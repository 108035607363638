import _ from "lodash";

export function slabToSlabLevels(levelDataFields) {
  return levelDataFields.filter((dataField) => {
    const rawHeight = _.get(dataField, "fieldContent.height");
    const height = rawHeight && _.isNumber(rawHeight) ? rawHeight : null;

    return height;
  });
}

export function totalSlabToSlabHeight(levelDataFields) {
  return (
    _.sumBy(slabToSlabLevels(levelDataFields), function (dataField) {
      const rawHeight = dataField.fieldContent.height;
      const height = rawHeight && _.isNumber(rawHeight) ? rawHeight : 0;
      return height;
    }) || 0
  );
}
